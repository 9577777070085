
<template>
    <div id="order-list-table">
        <vx-card ref="filterCard" title="Filter orders by status" class="user-list-filters mb-8" actionButtons
          @refresh="resetColFilters" @remove="resetColFilters">
            <div class="vx-row">
                <div class="vx-col w-full">
                    <div class="mt-3">
                        <ul class="leftx sm:flex items-center">
                            <li class="mr-8">
                              <vs-radio v-model="defaultStatus" vs-value="all">All</vs-radio>
                            </li>
                            <li class="mr-10">
                              <vs-radio v-model="defaultStatus" color="#6c757d" vs-value="draft">Draft</vs-radio>
                            </li>
                            <li class="mr-10">
                              <vs-radio color="#0dcaf0" v-model="defaultStatus" vs-value="pending">Pending</vs-radio>
                            </li>
                            <li class="mr-10">
                              <vs-radio color="warning" v-model="defaultStatus" vs-value="delivered">Delivered</vs-radio>
                            </li>
                            <li class="mr-10">
                              <vs-radio color="success" v-model="defaultStatus" vs-value="completed">Completed</vs-radio>
                            </li>
                            <li>
                                <vs-radio color="danger" v-model="defaultStatus" vs-value="refunded">Refunded</vs-radio>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </vx-card>

        <div id="orders-table-loading" class="vx-card p-6 vs-con-loading__container">
            <div class="flex flex-wrap items-center">
                <!-- ITEMS PER PAGE -->
                <div class="flex-grow">
                  <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                      <span v-if="pageData.total" class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ pageData.total - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : pageData.total }} of {{ pageData.total }}</span>
                      <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                    <vs-dropdown-item @click="itemsPerPage = 10">
                        <span>10</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="itemsPerPage = 50">
                        <span>50</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="itemsPerPage = 100">
                        <span>100</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="itemsPerPage = 200">
                        <span>200</span>
                    </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>

                <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
                <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" 
                v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
                <!-- <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Export as CSV</vs-button> -->

                <!-- ACTION - DROPDOWN -->
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                      <span class="mr-2 leading-none">Actions</span>
                      <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                      <vs-dropdown-item @click="updateStatus()">
                          <span class="flex items-center">
                          <feather-icon icon="CircleIcon" svgClasses="h-4 w-4" class="mr-2" />
                          <span>Update&nbsp;Status</span>
                          </span>
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="confirmMassDelete()">
                          <span class="flex items-center">
                          <feather-icon icon="CircleIcon" svgClasses="h-4 w-4" class="mr-2" />
                          <span>Delete</span>
                          </span>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </div>

            <!-- AgGrid Table -->
            <ag-grid-vue
                ref="agGridTable"
                :components="components"
                :gridOptions="gridOptions"
                class="ag-theme-material w-100 my-4 text-sm ag-grid-table"
                :columnDefs="columnDefs"
                :defaultColDef="defaultColDef"
                :rowData="orders"
                rowSelection="multiple"
                colResizeDefault="shift"
                :animateRows="true"
                :floatingFilter="true"
                domLayout='autoHeight'
                :pagination="false"
                :suppressPaginationPanel="true"
                :enableRtl="$vs.rtl"
                @cell-clicked="onCellClicked">
            </ag-grid-vue>

            <vs-pagination
            :total="pageData.last_page || 1"
            :max="7"
            v-model="currentPage" />
        </div>
        <vs-popup class="holamundo" @close="close" title="Update order status" :active.sync="statusObj.popup">
            <p class="text-sm" >Make sure you are confirm this operation.</p>
            <vs-divider/>
            <div class="vx-row">
            <div class="vx-col sm:w-3/4 mb-sm-0 mb-3 w-full">
              <vs-select autocomplete v-model="statusObj.new" class="select-large w-full">
                <vs-select-item :key="index" :value="status.value" :text="status.text" v-for="(status, index) in statusObj.list" />
              </vs-select>
            </div>
            <div class="vx-col sm:w-1/4 w-full">
              <span class="h-auto">
                <vs-button class="ml-auto" @click="proceedUpdateStatus()">Update</vs-button>
              </span>
            </div>
            </div>
        </vs-popup>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import { AgGridVue } from "ag-grid-vue"
// Cell Renderer
import CellRendererLink from "./cell-renderer/CellRendererLink.vue"
import CellRendererActions from "./cell-renderer/CellRendererActions.vue"
import CellRendererAmount from "./cell-renderer/CellRendererAmount.vue"
import CellRendererDate from "./cell-renderer/CellRendererDate.vue"
import CellRendererDeliveryTime from "./cell-renderer/CellRendererDeliveryTime.vue"
import _ from 'lodash';

export default {
  name:"order-list-table",
  props:{
    buyerId: {
      type: Number,
      default: null
    }
  },
  components: {
    vSelect,
    AgGridVue,
    // Cell Renderer
    CellRendererLink,
    CellRendererActions,
    CellRendererAmount,
    CellRendererDate,
    CellRendererDeliveryTime
  },
  data() {
    return {
      // Filter Options
      defaultStatus: "pending",
      searchQuery: "",
      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: 'Number',
          field: 'number',
          width: 200,
          filter: true,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true,
        },
        {
          headerName: 'Buyer',
          field: 'buyer.first_name',
          filter: true,
          minWidth: 150,
          cellRendererFramework: 'CellRendererLink',
        },
        {
          headerName: 'Product',
          field: 'product.name',
          filter: true,
          width: 120,
        },
        {
          headerName: 'Date',
          field: 'created_at',
          filter: true,
          width: 150,
          cellRendererFramework: 'CellRendererDate',
        },
        {
          headerName: 'Del_Time',
          field: 'delivery_time',
          filter: true,
          width: 100,
          cellClass: "text-center",
          cellRendererFramework: 'CellRendererDeliveryTime',
        },
        {
          headerName: 'Amount',
          field: 'amount',
          filter: true,
          width: 80,
          cellRendererFramework: 'CellRendererAmount',
        },
        {
          headerName: 'Status',
          field: 'status',
          filter: true,
          width: 120,
        },
        {
          headerName: 'Actions',
          field: 'transactions',
          width: 150,
          cellRendererFramework: 'CellRendererActions',
        },
      ],
      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererActions,
        CellRendererAmount,
        CellRendererDate,
        CellRendererDeliveryTime,
      },
      selectedData: [],
      statusObj: {
        list: [
          { value: 'pending',text: 'Pending'},
          { value: 'delivered',text: 'Delivered'},
          { value: 'completed',text: 'Completed'},
          { value: 'refunded',text: 'Refunded'}
        ],
        new: "completed",
        popup: false,
      }
    }
  },
  watch: {
    "$route.query":"refreshQueryStatus",
    defaultStatus (val) {
      this.fetchOrders()
    }
  },
  computed: {
    pageData(){
      return this.$store.state.orderList.pageData
    },
    orders() {
      return this.pageData.data.filter((order) => this.defaultStatus == "all" 
            || (order.status == this.defaultStatus))
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchOrders(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchOrders(1, val)
      }
    },
    selectedIds(){
      return [...this.selectedData.map(node => node.id)];
    }
  },
  methods: {
    refreshQueryStatus(){
      if(this.$route.query.status){
        this.defaultStatus = this.$route.query.status
      }
    },
    onCellClicked(params){
      this.$router.push({path: `/orders/${params.data.id}`})
    },
    getSelectedRows(){
      const selectedNodes = this.gridApi.getSelectedNodes();
      this.selectedData = selectedNodes.map(node => node.data);
    },
    updateStatus(){
      this.getSelectedRows();
      if(this.selectedData.length < 1){ this.alertError("You have not selected any item"); return}
      this.statusObj.popup = true
    },
    proceedUpdateStatus(){
      const formData = new FormData()
      formData.append('data', JSON.stringify({orders: this.selectedIds, status: this.statusObj.new}))
      this.resquestProcessor(formData,'update-status')
    },
    confirmMassDelete(){
      this.getSelectedRows();
      let items = this.selectedData.length;
      if(items < 1){ this.alertError("You have not selected any item"); return}
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: 'Are you sure?',
        text: `You are about to delete ${items} order${(items > 1)? 's':'' }`,
        accept: this.proceedMassDelete,
        acceptText: "Delete",
      })
    },
    proceedMassDelete(){
      const formData = new FormData()
      formData.append('data', JSON.stringify({items: this.selectedIds}))
      this.resquestProcessor(formData,'mass-delete')
    },
    resquestProcessor(formData, action){
      this.$vs.loading({color: "#444", type: "sound"})
      this.$http.post(`/orders/${action}`,formData)
      .then(response => {
        this.$vs.loading.close()
        if(response.data.success){
          this.$store.dispatch("orderList/fetchOrders")
          this.initializeAll();
        }
     })
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchOrders()
    }, 2000),
    fetchOrders(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.defaultStatus != 'all'){ payload.status = this.defaultStatus }
      if(this.buyerId){ payload.buyer_id = this.buyerId }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.$vs.loading({ container: '#orders-table-loading', color: "#444", type: "sound"})
      this.$store.dispatch("orderList/fetchOrders", payload)
      .then(response => {
        this.$vs.loading.close('#orders-table-loading > .con-vs-loading')
     })
    },
    resetColFilters() {
      // Reset Grid Filter
      this.gridApi.setFilterModel(null)
      this.gridApi.onFilterChanged()
      this.$refs.filterCard.removeRefreshAnimation()
    },
    initializeAll(){
      this.statusObj.popup = false;
      this.selectedData = []
      this.$validator.reset()
    },
    close(){
      this.initializeAll();
    }
  },
  mounted() {
    this.$store.commit('TOGGLE_REDUCE_BUTTON', true)
    this.gridApi = this.gridOptions.api
    if(this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(".ag-header-container")
      header.style.left = "-" + String(Number(header.style.transform.slice(11,-3)) + 9) + "px"
    }
    this.refreshQueryStatus()
    if(this.defaultStatus == "pending"){this.fetchOrders()}
  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
.vs-popup--content{
  height:100%;
}
</style>
