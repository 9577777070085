<template>
  <div class="flex items-center">
  	<router-link :to="'/orders/' + params.data.id" @click.stop.prevent class="text-inherit hover:text-primary">{{ params.value | delivery_time}}</router-link>
  </div>
</template>

<script>
    export default {
        name: 'CellRendererDeliveryTime',
    }
</script>
